import React from "react";
import Button from "../components/button";
import Container from "../components/container";
import BottomNav from "../components/bottomNav";
import { useSpring, animated } from "react-spring";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Link from "@material-ui/core/Link";

const ENDPOINT = "https://websocket.reckon.ai";

const AppLayout = ({
  children,
  style,
  title,
  logo,
  onClick,
  bottomNav = false,
  ...props
}) => {
  if (logo) {
    title = (
      <React.Fragment>
        <img src={process.env.PUBLIC_URL + "/logo123.svg"} alt="ponto" />
        <Link color="inherit" href="https://www.reckon.ai"  style={{ position: "absolute", right: "30px" }}>
          <img
            src={process.env.PUBLIC_URL + "/logo reckon.svg"}
            alt="reckon"
           
          />
        </Link>
      </React.Fragment>
    );
  }
  const mainSpring = useSpring({
    from: { x: 0 },
    x: 1,
  });
  const center = props.center
    ? { display: "flex", justifyContent: "center" }
    : {};
  const onClose = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      className="app-layout"
      style={{ backgroundColor: "#F2F2F2",marginLeft:"128px !important", ...style }}
    >
      {title ? (
        <Container
          as={animated.div}
          className="nav-bar"
          style={{
            opacity: mainSpring.x.interpolate({
              range: [0, 1],
              output: [0, 1],
            }),
            transform: mainSpring.x
              .interpolate({
                range: [0, 1],
                output: [-100, 0],
              })
              .interpolate((x) => `translate3d(0, ${x}%, 0`),
            ...center,
         
          }}
        >
          <Button className="nav-bar__back" onClick={onClose}>
            {" "}
            {onClick ? (
              <ArrowBackIcon style={{ fontSize: "1em", marginRight: "5px" }} />
            ) : null}
            {title}
          </Button>
        </Container>
      ) : null}
      {children}
      {bottomNav ? <BottomNav match location /> : null}
    </div>
  );
};
export default AppLayout;
